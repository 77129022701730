import PropTypes from 'prop-types'

import { H3, MainModal, P2 } from '@moneymade/moneymade-ui'
import styles from './AdvertiserModal.module.scss'

const AdvertiserModal = ({ isOpen, handleCloseModal }) => (
  <MainModal isOpen={isOpen} handleClose={handleCloseModal} maxWidth={500}>
    <section className={styles.AdvertiserModal}>
      <H3 className={styles.Title}>Advertiser disclosure</H3>

      <P2 weight="light">
        Some offers that appear on this site are from third party advertisers from which MoneyMade receives
        compensation. This compensation may impact how and where products appear on this site (including, for example,
        the order in which they appear). It is this compensation that enables MoneyMade to provide you with our services
        at no charge. MoneyMade strives to provide a wide array of offers for our users, but our offers do not represent
        all financial services companies or products.
      </P2>
      <P2 weight="light">
        MoneyMade receives compensation from third-party advertisers, but that doesn’t affect our editors&apos;
        opinions. Our marketing partners don’t influence our editorial content. It’s accurate to the best of our
        knowledge when it’s posted.
      </P2>
      <P2 weight="light">
        Past performance or target returns is not indicative of future returns. No representation or warranty is made
        that any returns indicated will be achieved.
      </P2>
    </section>
  </MainModal>
)

AdvertiserModal.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func
}

AdvertiserModal.defaultProps = {
  isOpen: false,
  handleCloseModal: () => {}
}

export default AdvertiserModal
