import Link from 'next/link'
import { H5, P2 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import { ROUTES } from 'static/menusConstants'
import { setPrevPos } from 'utils'
import PlatformMeta from 'components/ui/PlatformMeta/PlatformMeta'
import PlatformIndustry from 'components/ui/PlatformIndustry/PlatformIndustry'
import { getCdnImg } from 'utils/getters'
import ImgLazy from 'components/ui/ImgLazy/ImgLazy'

import styles from './PlatformCard.module.scss'

const PlatformCard = ({
  logo,
  name,
  averageRating,
  industry,
  industries,
  actualReturn,
  slug,
  collapsedDescription,
  minInvestment,
  target,
  dataIndustry,
  industriesAvgAll
}) => {
  const industryTop = dataIndustry?.dataSlug ? dataIndustry : industries[0]?.dataSlug ? industries[0] : industries[1]

  return (
    <Link href={`/${ROUTES.discover}/${slug}`}>
      <a
        className={styles.PlatformCard}
        onClick={() => setPrevPos('home', document.documentElement.scrollTop)}
        rel="noreferrer"
        aria-hidden
      >
        <div className={styles.Header}>
          <ImgLazy src={getCdnImg(logo, 48)} alt={name} width={48} height={48} />
          {/* <Avatar src={getCdnImg(logo, 48)} alt={name} width={48} height={48} /> */}

          <div className={styles.Name}>
            <H5 weight="semibold">{name}</H5>
            <PlatformMeta averageRating={averageRating} />
          </div>
        </div>

        <PlatformMeta industry={industry} minInvestment={minInvestment} />

        <P2 className={styles.Description}>{collapsedDescription}</P2>

        <div className={styles.Info}>
          <div className={styles.TopPart}>
            <PlatformIndustry
              actualReturn={actualReturn}
              nameplatform={name}
              industryTop={industryTop}
              avg={industriesAvgAll[industryTop?.dataSlug]}
              target={target}
              toLeft
            />
          </div>
        </div>
      </a>
    </Link>
  )
}

PlatformCard.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  averageRating: PropTypes.number,
  industry: PropTypes.string,
  slug: PropTypes.string,
  collapsedDescription: PropTypes.string,
  minInvestment: PropTypes.number,
  actualReturn: PropTypes.number,
  industries: PropTypes.arrayOf(PropTypes.shape({ dataSlug: PropTypes.string })),
  target: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string
  }),
  dataIndustry: PropTypes.shape({ dataSlug: PropTypes.string }),
  industriesAvgAll: PropTypes.shape({})
}

PlatformCard.defaultProps = {
  logo: '',
  name: '',
  averageRating: 0,
  industry: '',
  slug: '',
  actualReturn: 0,
  collapsedDescription: '',
  minInvestment: 0,
  industries: [{ dataSlug: '' }],
  target: {
    min: '',
    max: ''
  },
  dataIndustry: [{ dataSlug: '' }],
  industriesAvgAll: {}
}

export default PlatformCard
