/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { H1, H2, H4, P2, P4, MainButton, Radio, Icon, Container, Input, useWindowSize } from '@moneymade/moneymade-ui'

import { ROUTES } from 'static/menusConstants'
import { QUIZ_PARAMS, RESULT_DEFAULT } from 'modules/home/HomePage/homePageConstants'

import {
  validateEmail,
  setTradingQuizStatus,
  setUserEmailStatus,
  getfilterQuizResults,
  getMatchMeData
} from 'modules/home/HomePage/homePageUtils'

import styles from './FullQuiz.module.scss'

const getMatches = (result, investments) =>
  getfilterQuizResults(investments, result.risk, result.cash, result.timeframe, result.accredited)

const QUIZ_DATA = [
  {
    style: 'home',
    title: ['Investments', 'matched', 'to you'],
    subTitle: 'Instantly get matched to investments suited to you with our MatchMe tool.'
  },
  {
    style: 'discovery',
    title: [' Get your', 'investment', 'matches'],
    subTitle: 'Instantly discover investments that match your investor profile.'
  }
]

const FullQuiz = ({
  investments,
  quiz,
  industriesAll,
  assetPagesSlugs,
  type,
  handleSetAppField,
  handleSetAppFields,
  handleBlur
}) => {
  const { push } = useRouter()
  const { screenWidth } = useWindowSize()
  const [startQuiz, setStartQuiz] = useState(true)
  const [step, setStep] = useState(0)
  const [matches, setMatches] = useState(investments)
  const [result, setResult] = useState(RESULT_DEFAULT)
  const [radios, setRadios] = useState(0)
  const [email, setEmail] = useState('')
  // btns iOS fix
  const [show, setShow] = useState(true)

  // step 4 is reserved for radio
  const isRadio = useMemo(() => step === 4, [step])
  // show Go button
  const isGo = useMemo(() => result.accredited[0] !== '4', [result.accredited])
  // is home
  const isHome = useMemo(() => type === 'home', [])
  // get quiz type
  const quizType = useMemo(() => (isHome ? QUIZ_DATA[0] : QUIZ_DATA[1]), [])

  const handleClickRadio = (field, value) => {
    // duplicate to display different values
    setRadios(value)
    // 1, 2 - '1', 3 ('None') - 2
    setResult(prevData => ({ ...prevData, [field]: value === '3' ? ['2'] : ['1'] }))
  }

  const getData = async matchesNew => {
    // get result data
    const { platforms, assets, readSlugs } = await getMatchMeData(
      matchesNew,
      investments,
      industriesAll,
      assetPagesSlugs
    )
    // quiz answers
    const quizAnswersRemotely = {
      risk: result.risk,
      cash: result.cash,
      timeframe: result.timeframe,
      accredited: result.accredited
    }
    // generate quiz result object
    const quizData = {
      platforms,
      assets,
      readSlugs,
      quizAnswersRemotely,
      url: `?quizAnswersRemotely=${JSON.stringify(quizAnswersRemotely)}`
    }
    // set investments quiz result to redux store
    handleSetAppField({ key: 'quiz', value: quizData })
    // set quiz result to LS
    setTradingQuizStatus(quizData)
    // set blur effect
    handleBlur()
    // set email step
    setStep(prevData => prevData + 1)
  }

  const handleClickGo = () => {
    setStep(prevData => prevData + 1)
    // get matches
    const matchesLocal = getMatches(result, investments)
    // get data by matches
    getData(matchesLocal)
  }

  const handleClick = async (field, value) => {
    // set local step
    let stepLocal = 0
    // check the field name
    if (field === 'accredited') {
      // if sure go to step 5
      if (value !== '4') {
        stepLocal = 5
        setStep(stepLocal)
      }
      // if 'Unsure' go to step 4
      else {
        stepLocal = step + 1
        setStep(stepLocal)
      }
    } else {
      stepLocal = step + 1
      setStep(stepLocal)
    }
    // result
    const resultLocal = { ...result, [field]: field === 'risk' && value === '4' ? ['1', '2', '3'] : [value] }
    // update result
    setResult(resultLocal)
    // get result and matches
    const matchesLocal = getMatches(result, investments)
    // update matches
    setMatches(matchesLocal)
    // show buttons
    setShow(true)
    // if last step
    if (stepLocal === 5) getData(matchesLocal)
  }

  const handleSeeResult = async () => {
    // Get investmets ids array
    const platforms = quiz.platforms?.map(investment => investment?.slug) || []
    // Get investmets ids array
    const assets = quiz.assets?.map(asset => asset?.assetSlug) || []
    // Get investmets ids array
    const readSlugs = quiz.readSlugs || []
    // quiz answers
    const quizAnswersRemotely = {
      risk: result.risk,
      cash: result.cash,
      timeframe: result.timeframe,
      accredited: result.accredited
    }
    // generate quiz mail result that will be sent on the matchMe page later
    const quizMailResult = {
      email,
      platforms,
      assets,
      readSlugs,
      url: `?quizAnswersRemotely=${JSON.stringify(quizAnswersRemotely)}`
    }
    // save data to store
    handleSetAppFields([
      { key: 'quizMailSent', value: false },
      { key: 'quizMailResult', value: quizMailResult }
    ])
    // set user email to LS
    setUserEmailStatus(email)
    // go to Match Me page
    push(`/${ROUTES.matchMe}`)
  }

  return (
    <div className={styles.FullQuiz}>
      {step < 6 && (
        <div
          className={`${styles.Steps} ${isHome ? styles.Home : styles.Discovery} ${
            step < 5 && !isRadio ? styles.StepsAll : styles.StepsRadio
          }`}
        >
          <div className={styles.Container}>
            {!startQuiz ? (
              <>
                <div className={`${styles.Title} ${isHome ? styles.Home : styles.Discovery}`}>
                  <H1
                    type="heading"
                    weight="bold"
                    nestedStyles
                    font={{
                      default: { fontSize: '36px', lineHeight: '40px' },
                      sm: isHome ? { fontSize: '48px', lineHeight: '56px' } : { fontSize: '36px', lineHeight: '40px' },
                      md: isHome ? { fontSize: '48px', lineHeight: '56px' } : { fontSize: '36px', lineHeight: '40px' },
                      lg: isHome ? { fontSize: '48px', lineHeight: '56px' } : { fontSize: '36px', lineHeight: '40px' }
                    }}
                  >
                    {quizType?.title?.map(titl => (
                      <span key={titl}>{titl}</span>
                    ))}
                  </H1>
                </div>

                <H4
                  className={`${styles.SubTitle} ${isHome ? styles.Home : styles.Discovery}`}
                  font={{
                    default: { fontSize: '18px', lineHeight: '26px' },
                    sm: isHome ? { fontSize: '24px', lineHeight: '32px' } : { fontSize: '20px', lineHeight: '28px' },
                    md: isHome ? { fontSize: '24px', lineHeight: '32px' } : { fontSize: '20px', lineHeight: '28px' },
                    lg: isHome ? { fontSize: '24px', lineHeight: '32px' } : { fontSize: '20px', lineHeight: '28px' }
                  }}
                >
                  {quizType?.subTitle}
                </H4>

                <MainButton
                  weight="light"
                  fontType="main"
                  className={styles.BtnQuiz}
                  onClick={() => setStartQuiz(true)}
                  iconAfter={<Icon size={16} className="icon-arrow-right" />}
                  size={isHome ? 'md' : 'sm'}
                >
                  {screenWidth < 601 ? 'MatchMe Now' : 'Get Matched Now'}
                </MainButton>
              </>
            ) : (
              <>
                {step < 5 ? (
                  !isRadio ? (
                    <>
                      <H2
                        type="heading"
                        weight="bold"
                        className={`${styles.Desc} ${isHome ? styles.Home : styles.Discovery}`}
                        font={{
                          default: { fontSize: '24px', lineHeight: '32px' },
                          sm: isHome
                            ? { fontSize: '36px', lineHeight: '40px' }
                            : { fontSize: '24px', lineHeight: '32px' },
                          md: isHome
                            ? { fontSize: '36px', lineHeight: '40px' }
                            : { fontSize: '24px', lineHeight: '32px' },
                          lg: isHome
                            ? { fontSize: '36px', lineHeight: '40px' }
                            : { fontSize: '24px', lineHeight: '32px' }
                        }}
                      >
                        {QUIZ_PARAMS[step]?.title}
                      </H2>

                      <div className={`${styles.BtnsLayout} ${isHome ? styles.Home : styles.Discovery}`}>
                        <div
                          className={`${styles.Btns} ${show ? styles.Show : styles.Hide} ${
                            isHome ? styles.Home : styles.Discovery
                          }`}
                        >
                          {QUIZ_PARAMS[step]?.btns?.map(({ title, value }) => (
                            <MainButton
                              key={title}
                              layout="outlined"
                              color="white"
                              weight="normal"
                              fontType="main"
                              className={styles.Btn}
                              onClick={() => {
                                setShow(false)
                                setTimeout(() => handleClick(QUIZ_PARAMS[step]?.field, value), 10)
                              }}
                            >
                              {title}
                            </MainButton>
                          ))}

                          <div className={`${styles.Right} ${isHome ? styles.Home : styles.Discovery}`} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <H2
                        type="heading"
                        weight="bold"
                        className={`${styles.RadiosDesc} ${isHome ? styles.Home : styles.Discovery}`}
                        font={{
                          default: { fontSize: '24px', lineHeight: '32px' },
                          sm: isHome
                            ? { fontSize: '36px', lineHeight: '40px' }
                            : { fontSize: '24px', lineHeight: '32px' },
                          md: isHome
                            ? { fontSize: '36px', lineHeight: '40px' }
                            : { fontSize: '24px', lineHeight: '32px' },
                          lg: isHome
                            ? { fontSize: '36px', lineHeight: '40px' }
                            : { fontSize: '24px', lineHeight: '32px' }
                        }}
                      >
                        {QUIZ_PARAMS[3]?.titleRadio}
                      </H2>

                      <div className={`${styles.Radios} ${isHome ? styles.Home : styles.Discovery}`}>
                        {QUIZ_PARAMS[3]?.radios?.map(({ title, value }) => (
                          <div key={title} className={styles.RadioContainer}>
                            <Radio
                              color={isHome ? 'turquoise' : 'red'}
                              value={value}
                              label={title}
                              labelPosition="right"
                              onChange={() => handleClickRadio(QUIZ_PARAMS[3]?.field, value)}
                              checked={value === radios}
                            />
                          </div>
                        ))}

                        <MainButton
                          weight="normal"
                          fontType="main"
                          className={`${styles.Btn} ${isHome ? styles.Home : styles.Discovery}`}
                          onClick={handleClickGo}
                          disabled={isRadio && !isGo}
                        >
                          Go
                        </MainButton>
                      </div>
                    </>
                  )
                ) : null}

                {step <= QUIZ_PARAMS.length - 1 && !isRadio && (
                  <div className={styles.Bottom}>
                    <div className={styles.Dots}>
                      {QUIZ_PARAMS.map((_, index) => (
                        <div
                          key={index}
                          className={`${styles.Dot} ${
                            step === 0 && index === 1
                              ? styles.NotActiveSecond
                              : step >= index
                              ? styles.Active
                              : styles.NotActive
                          }`}
                        />
                      ))}
                    </div>

                    {step > 0 && (
                      <P4
                        className={`${styles.Text} ${isHome ? styles.Home : styles.Discovery}`}
                      >{`${matches?.length} Investment Matches`}</P4>
                    )}
                  </div>
                )}

                {step === 5 && (
                  <div className={`${styles.Loading} ${isHome ? styles.Home : styles.Discovery}`}>
                    <Icon icon="icon-blick" size={24} />
                    <P2>Loading matches...</P2>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {step >= 6 && (
        <div className={`${styles.Result} ${styles.Home}`}>
          <Container className={`${styles.Container} ${styles.Home}`}>
            <div className={`${styles.Img} ${styles.Home}`}>
              <Icon icon="icon-check-mark" size={18} />
            </div>

            <H4 weight="semibold">{`We've paired you with ${matches?.length} investment matches.`}</H4>

            <P2 weight="light">Enter your email to instantly see your results</P2>

            <Input
              placeholder="Email address"
              type="email"
              onChange={({ target: { value } }) => setEmail(value)}
              autoComplete="email"
            />

            <MainButton
              weight="normal"
              fontType="main"
              className={styles.Btn}
              onClick={handleSeeResult}
              disabled={!validateEmail(email)}
            >
              See Results
            </MainButton>
          </Container>
        </div>
      )}
    </div>
  )
}

FullQuiz.propTypes = {
  investments: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
      slug: PropTypes.string
    })
  ),
  quiz: PropTypes.shape({
    platforms: PropTypes.arrayOf(PropTypes.object),
    assets: PropTypes.arrayOf(PropTypes.object),
    readSlugs: PropTypes.arrayOf(PropTypes.string)
  }),
  assetPagesSlugs: PropTypes.arrayOf(PropTypes.string),
  industriesAll: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  handleSetAppField: PropTypes.func,
  handleSetAppFields: PropTypes.func,
  handleBlur: PropTypes.func
}

FullQuiz.defaultProps = {
  investments: [],
  quiz: {},
  assetPagesSlugs: [],
  industriesAll: [],
  type: 'home',
  handleSetAppField: () => {},
  handleSetAppFields: () => {},
  handleBlur: () => {}
}

export default FullQuiz
