import { P1, P3, useWindowSize } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import { toMoneyFormat } from 'utils'

import styles from './PlatformIndustry.module.scss'

const PlatformIndustry = ({ actualReturn, nameplatform, avg, minInvest, target, toLeft }) => {
  const { screenWidth } = useWindowSize()

  return (
    <>
      {avg ? (
        <div className={styles.InfoBlock}>
          <P3 className={styles.Title}>Invest from</P3>
          <div className={`${styles.Content} ${toLeft ? styles.ToLeft : ''} `}>
            <P1 weight="normal">{minInvest ? `$${toMoneyFormat(minInvest, 0)}` : '-'}</P1>
          </div>
        </div>
      ) : null}

      {actualReturn > 0 || actualReturn < 0 ? (
        <div className={styles.InfoBlock}>
          <P3 className={styles.Title}>
            1Y {nameplatform.lenght > 17 && screenWidth < 601 ? `${nameplatform.slice(0, 14)}...` : nameplatform}
          </P3>
          <div className={`${styles.Content} ${toLeft ? styles.ToLeft : ''} `}>
            <P1 weight="normal" className={`${actualReturn > 0 ? 'color-green' : 'color-red'}`}>
              {`${actualReturn > 0 ? '+' : ''}${actualReturn}`}%
            </P1>
          </div>
        </div>
      ) : target ? (
        <div className={styles.InfoBlock}>
          <P3 className={styles.Title}>Target return</P3>
          <div className={`${styles.Content} ${toLeft ? styles.ToLeft : ''} `}>
            <P1 weight="normal">
              {target ? (target.min ? `${target.min}%` : 'Varied') : ''}
              {target ? target.max && ` - ${target.max}%` : ''}
            </P1>
          </div>
        </div>
      ) : null}
    </>
  )
}

PlatformIndustry.propTypes = {
  actualReturn: PropTypes.number,
  nameplatform: PropTypes.string,
  industryTop: PropTypes.shape({
    name: PropTypes.string
  }),
  minInvest: PropTypes.number,
  avg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  target: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string
  }),
  toLeft: PropTypes.bool
}

PlatformIndustry.defaultProps = {
  actualReturn: 0,
  nameplatform: '',
  avg: 0,
  target: null,
  minInvest: 0,
  industryTop: {},
  toLeft: false
}

export default PlatformIndustry
