import PropTypes from 'prop-types'
import { Container, P2, H2, MainLink, useWindowSize, H4, H5 } from '@moneymade/moneymade-ui'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ROUTES } from 'static/menusConstants'
import PlatformCard from 'modules/home/HomePage/MmTrend/PlatformCard/PlatformCard'
import styles from './MmTrend.module.scss'

const MmTrend = ({ title, hidetitle, investments, industriesAvgAll }) => {
  const { screenWidth } = useWindowSize()

  return (
    <Container className={styles.MmTrend}>
      <div className={styles.Left}>
        <div className={styles.Logo}>
          {screenWidth > 961 ? (
            <img
              src="https://assets.moneymade.io/images/home/illustration.svg"
              alt="logo"
              className={styles.Image}
              width={94}
              height={74}
            />
          ) : null}
          {screenWidth > 961 ? (
            <H2 weight="semibold" className={styles.Title}>
              {title}
            </H2>
          ) : screenWidth > 601 ? (
            <H4 weight="semibold" className={styles.Title}>
              Trending Platforms
            </H4>
          ) : (
            <H5 weight="semibold" className={styles.Title}>
              Trending Platforms
            </H5>
          )}
        </div>
        <MainLink href={`/${ROUTES.discover}`} size="md" fontType="main" weight="normal" className={styles.Link}>
          {screenWidth > 961 ? 'Explore Platforms' : 'Explore All'}
        </MainLink>
      </div>
      <div className={styles.Right}>
        {screenWidth > 961 && !hidetitle ? (
          <P2 weight="normal" className={styles.Title}>
            Trending
          </P2>
        ) : null}

        <Swiper
          className={styles.Cards}
          modules={[Pagination]}
          // navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
          spaceBetween={32}
          pagination={{ clickable: true }}
          breakpoints={{
            890: {
              slidesPerView: investments.length > 2 ? 2.5 : investments.length
            },
            600: {
              slidesPerView: investments.length > 2 ? 2.1 : investments.length
            },
            0: {
              slidesPerView: 1
            }
          }}
        >
          {investments?.map((card, index) => (
            <SwiperSlide key={`indystry-${index}`}>
              <PlatformCard {...card} industriesAvgAll={industriesAvgAll} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  )
}

MmTrend.propTypes = {
  title: PropTypes.string,
  investments: PropTypes.arrayOf(PropTypes.object),
  trendingInfo: PropTypes.shape({}),
  hidetitle: PropTypes.bool,
  reviews: PropTypes.arrayOf(PropTypes.object),
  industries: PropTypes.arrayOf(PropTypes.object),
  topIndustry: PropTypes.arrayOf(PropTypes.object),
  industriesAvgAll: PropTypes.shape({}),
  assetPagesSlugs: PropTypes.arrayOf(PropTypes.string)
}

MmTrend.defaultProps = {
  title: '',
  investments: [],
  hidetitle: false,
  trendingInfo: {},
  reviews: [],
  industries: [],
  topIndustry: [],
  industriesAvgAll: {},
  assetPagesSlugs: []
}

export default MmTrend
