/* eslint-disable prefer-destructuring */
import moment from 'moment'
import { getDateData, toMoneyFormatWithLetter } from 'utils'

export const toDateConverter = timestamp => {
  const dateTimestamp = new Date(timestamp)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const year = dateTimestamp.getFullYear()
  const month = months[dateTimestamp.getMonth()]
  const date = dateTimestamp.getDate()

  return { year, month, date }
}

export const getMonthDate = str => {
  const { month, date } = toDateConverter(str)
  return `${month} ${date}`
}

export const getMonthAndYearDate = str => {
  const { month, year, date } = toDateConverter(str)
  return `${month} ${date}, ${year}`
}

export const sortByPropDesc = prop => (a, b) => a[prop] > b[prop] ? -1 : b[prop] > a[prop] ? 1 : 0

export const getWebpart = (collections, name) => collections?.find(({ type }) => type === name) || null

const reg = /#/g
export const isSharpLink = link => new RegExp(reg).test(link)

export const getYearData = values => {
  if (values.length) {
    const date = new Date(values[0].datetime)
    // Substract 1 year from current datetime
    date.setFullYear(date.getFullYear() - 1)

    const filteredValues = values.filter(({ datetime }) => new Date(datetime) >= date)
    return filteredValues
  }
  return values
}

export const toConvertMoneyFull = val => {
  if (val < 1e3) return val
  if (val >= 1e3 && val < 1e6) return `${+(val / 1e3).toFixed(1)}<span className="unit">thousand</span>`
  if (val >= 1e6 && val < 1e9) return `${+(val / 1e6).toFixed(1)}<span className="unit">million</span>`
  if (val >= 1e9 && val < 1e12) return `${+(val / 1e9).toFixed(1)}<span className="unit">billion</span>`
  if (val >= 1e12) return `${+(val / 1e12).toFixed(1)}<span className="unit">trillion</span>`

  return val
}

export const toConvertMoney = val => {
  if (val < 1e3) return val
  if (val >= 1e3 && val < 1e6) return `${+(val / 1e3).toFixed(1)}K`
  if (val >= 1e6 && val < 1e9) return `${+(val / 1e6).toFixed(1)}M`
  if (val >= 1e9 && val < 1e12) return `${+(val / 1e9).toFixed(1)}B`
  if (val >= 1e12) return `${+(val / 1e12).toFixed(1)}T`

  return val
}

export const convertData = (data, timeline) => {
  switch (timeline) {
    case '1M':
      return moment(data).format('MMM DD')

    case '1Y':
      return moment(data).format('MMM')

    case '5Y':
      return moment(data).format('YYYY')

    case '10Y':
      return moment(data).format('YYYY')

    default:
      return data
  }
}

export const convertDataHighlights = values => {
  const value1Y = getDateData(values, '1Y')
  const value5Y = getDateData(values, '5Y')
  const consvertVolume = value1Y?.map(({ volume }) => Number(volume))

  const consvertClose1Y = value1Y?.map(({ close }) => ({ close: Number(close) }))
  const consvertClose5Y = value5Y?.map(({ close, datetime }) => ({ close: Number(close), datetime }))

  const sumVolume = consvertVolume.reduce((prevNumber, nextNumber) => prevNumber + nextNumber, 0)
  const avgVolume = sumVolume / consvertVolume.length || 0

  const min1Y = consvertClose1Y.length ? Math.min(...consvertClose1Y.map(item => item?.close)) : 0
  const max1Y = consvertClose1Y.length ? Math.max(...consvertClose1Y.map(item => item?.close)) : 0

  const min5Y = consvertClose5Y.length ? Math.min(...consvertClose5Y.map(item => item?.close)) : 0
  const max5Y = consvertClose5Y.length ? Math.max(...consvertClose5Y.map(item => item?.close)) : 0

  const min5Year = consvertClose5Y.find(item => min5Y === Number(item?.close))
  const max5Year = consvertClose5Y.find(item => max5Y === Number(item?.close))

  const oneYLow = value1Y?.[(value1Y?.length || 0) - 1]
  const oneYHigh = value1Y?.[0]
  const oneYGrowth = oneYHigh?.close
    ? (((Number(oneYHigh?.close) - Number(oneYLow?.close)) / Number(oneYLow?.close)) * 100)?.toFixed(2)
    : 0

  const fiveYLow = value5Y?.[(value1Y?.length || 0) - 1]
  const fiveYHigh = value5Y?.[0]

  return {
    oneYLow: {
      price: min1Y ? min1Y.toFixed(2) : 0,
      date: moment(oneYLow?.datetime).format('MMM DD, YYYY'),
      volume: toMoneyFormatWithLetter(Number(oneYLow?.volume)) || 0
    },
    oneYHigh: {
      price: max1Y ? max1Y.toFixed(2) : 0,
      date: moment(oneYHigh?.datetime).format('MMM DD, YYYY'),
      volume: toMoneyFormatWithLetter(Number(oneYHigh?.volume)) || 0
    },
    fiveYLow: {
      price: min5Y ? min5Y.toFixed(2) : 0,
      date: moment(min5Year?.datetime).format('MMM DD, YYYY'),
      volume: toMoneyFormatWithLetter(Number(fiveYLow?.volume)) || 0
    },
    fiveYHigh: {
      price: max5Y ? max5Y.toFixed(2) : 0,
      date: moment(max5Year?.datetime).format('MMM DD, YYYY'),
      volume: toMoneyFormatWithLetter(Number(fiveYHigh?.volume)) || 0
    },
    oneYGrowth,
    avgVolume: toMoneyFormatWithLetter(avgVolume) || 0,
    price: oneYHigh?.close ? Number(oneYHigh?.close).toFixed(2) : 0
  }
}

export const getPercentageOfArray = arr => Math.floor(arr * (75 / 100))

export const removeCardWidgetsAtEnd = arr => {
  let cardWidgetFound = false

  return arr.filter(item => {
    if (item.type === 'card-widget') {
      if (!cardWidgetFound) {
        cardWidgetFound = true // Keep the first "card-widget"
        return true
      }
      return false // Skip all subsequent "card-widget"
    }
    return true // Keep all other types
  })
}

export const findParentByTagNameUsingReduce = (child, tagName, maxLevels) => {
  // Collect ancestors up to the max level
  const ancestors = []
  let currentElement = child

  for (let i = 0; i < maxLevels && currentElement.parentElement; i++) {
    currentElement = currentElement.parentElement
    ancestors.push(currentElement)
  }

  // Use reduce to check for a parent with the matching tag name
  return ancestors.reduce((foundParent, parent) => {
    if (foundParent) return foundParent // If already found, stop reducing
    return parent.tagName.toLowerCase() === tagName.toLowerCase() ? parent : null
  }, null)
}
