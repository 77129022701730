import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAppFieldAction, setAppFieldsAction } from 'redux/app/actions'
import FullQuiz from 'modules/home/HomePage/FullQuiz/FullQuiz'

const FullQuizContainer = props => {
  const { quiz } = useSelector(({ app }) => app)

  const dispatch = useDispatch()

  const setAppField = useCallback(payload => dispatch(setAppFieldAction(payload)), [dispatch])
  const setAppFields = useCallback(payload => dispatch(setAppFieldsAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    quiz,
    handleSetAppField: setAppField,
    handleSetAppFields: setAppFields
  }

  return <FullQuiz {...updatedProps} />
}

export default FullQuizContainer
