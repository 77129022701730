/* eslint-disable no-console */
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'

import { HOTJAR_ID, HOTJAR_SV } from 'static/constants'

const InitTracking = () => {
  // Google Analytics Module init
  useEffect(() => {
    if (!ReactGA?.initialize) return

    try {
      ReactGA.initialize('UA-148517382-4', { gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' } })
    } catch {
      console.error('Faild to init Google Analytics')
    }
  }, [])

  // Init Tapafiliate
  useEffect(() => {
    if (!window?.tap) return

    try {
      window.tap('create', '25033-6cdd01', { integration: 'javascript' })
      window.tap('detect', { cookie_domain: 'moneymade.io' })
    } catch {
      console.error('Failed to init Tap')
    }
  }, [])

  // Init Hotjar
  useEffect(() => {
    if (!hotjar?.initialize) return

    try {
      hotjar.initialize(HOTJAR_ID, HOTJAR_SV)
    } catch {
      console.error('Faild to init Hotjar')
    }
  }, [])

  return null
}

export default InitTracking
