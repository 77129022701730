import { Rating } from '@moneymade/moneymade-ui'
import { toConvertMoney } from 'modules/learn/learnUtils'
import PropTypes from 'prop-types'

import styles from './PlatformMeta.module.scss'

const PlatformMeta = ({ averageRating, industry, asset, minInvestment, className }) => {
  const minValue = Number.isInteger(minInvestment) && String(toConvertMoney(minInvestment, 0))
  return (
    <div className={`${className || ''} ${styles.PlatformMeta}`}>
      {!!averageRating && <Rating rating={averageRating} layout="single" color="red" withNumber />}

      {industry && (
        <>
          {!!averageRating && <span className={styles.Dot}>•</span>}
          <p className={styles.Industry}>{industry.name ? industry.name : industry}</p>
        </>
      )}

      {minValue && (
        <>
          {(!!averageRating || industry) && <span className={styles.Dot}>•</span>}
          <p className={styles.MinInvestment}>Min ${minValue.toLowerCase()}</p>
        </>
      )}

      {asset && (
        <>
          {(!!averageRating || industry || minValue) && <span className={styles.Dot}>•</span>}
          <p className={styles.MinInvestment}>{asset}</p>
        </>
      )}
    </div>
  )
}

PlatformMeta.propTypes = {
  averageRating: PropTypes.number,
  industry: PropTypes.string,
  asset: PropTypes.string,
  minInvestment: PropTypes.number,
  className: PropTypes.string
}

PlatformMeta.defaultProps = {
  averageRating: null,
  industry: null,
  asset: null,
  minInvestment: null,
  className: null
}

export default PlatformMeta
